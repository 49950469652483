import sep2014 from "../../images/covers/2014/SEP 2014.jpg"
import oct2014 from "../../images/covers/2014/OCT 2014.jpg"
import nov2014 from "../../images/covers/2014/NOV 2014.jpg"
import dec2014 from "../../images/covers/2014/DEC 2014.jpg"

import jan2015 from "../../images/covers/2015/JAN 2015.jpg"
import feb2015 from "../../images/covers/2015/FEB 2015.jpg"
import mar2015 from "../../images/covers/2015/MAR 2015.jpg"
import apr2015 from "../../images/covers/2015/APR 2015.jpg"
import june2015 from "../../images/covers/2015/JUN 2015.jpg"
import july2015 from "../../images/covers/2015/JULY 2015.jpg"
import aug2015 from "../../images/covers/2015/AUG 2015.jpg"
import sep2015 from "../../images/covers/2015/SEP 2015.jpg"
import oct2015 from "../../images/covers/2015/OCT 2015.jpg"
import nov2015 from "../../images/covers/2015/NOV 2015.jpg"
import dec2015 from "../../images/covers/2015/DEC 2015.jpg"

import jan2016 from "../../images/covers/2016/JAN 2016.jpg"
import feb2016 from "../../images/covers/2016/FEB 2016.jpg"
import mar2016 from "../../images/covers/2016/MAR 2016.jpg"
import apr2016 from "../../images/covers/2016/APR 2016.jpg"
import may2016 from "../../images/covers/2016/MAY 2016.jpg"
import july2016 from "../../images/covers/2016/JULY 2016.jpg"
import aug2016 from "../../images/covers/2016/AUG 2016.jpg"
import sep2016 from "../../images/covers/2016/SEP 2016.jpg"
import oct2016 from "../../images/covers/2016/OCT 2016.jpg"
import nov2016 from "../../images/covers/2016/NOV 2016.jpg"
import dec2016 from "../../images/covers/2016/DEC 2016.jpg"

import jan2017 from "../../images/covers/2017/JAN 2017.jpg"
import feb2017 from "../../images/covers/2017/FEB 2017.jpg"
import mar2017 from "../../images/covers/2017/MAR 2017.jpg"
import apr2017 from "../../images/covers/2017/APR 2017.jpg"
import may2017 from "../../images/covers/2017/MAY 2017.jpg"
import july2017 from "../../images/covers/2017/JULY 2017.jpg"
import aug2017 from "../../images/covers/2017/AUG 2017.jpg"
import sep2017 from "../../images/covers/2017/SEP 2017.jpg"
import oct2017 from "../../images/covers/2017/OCT 2017.jpg"
import nov2017 from "../../images/covers/2017/NOV 2017.jpg"
import dec2017 from "../../images/covers/2017/DEC 2017.jpg"

import jan2018 from "../../images/covers/2018/JAN 2018.jpg"
import feb2018 from "../../images/covers/2018/FEB 2018.jpg"
import mar2018 from "../../images/covers/2018/MAR 2018.jpg"
import apr2018 from "../../images/covers/2018/APR 2018.jpg"
import jul2018 from "../../images/covers/2018/JUL 2018.jpg"
import aug2018 from "../../images/covers/2018/AUG 2018.jpg"
import sep2018 from "../../images/covers/2018/SEP 2018.jpg"
import oct2018 from "../../images/covers/2018/OCT 2018.jpg"
import nov2018 from "../../images/covers/2018/NOV 2018.jpg"
import dec2018 from "../../images/covers/2018/DEC 2018.jpg"

import jan2019 from "../../images/covers/2019/JAN 2019.jpg"
import feb2019 from "../../images/covers/2019/FEB 2019.jpg"
import mar2019 from "../../images/covers/2019/MAR 2019.jpg"
import apr2019 from "../../images/covers/2019/APR 2019.jpg"
import jul2019 from "../../images/covers/2019/JULY 2019.jpg"
import aug2019 from "../../images/covers/2019/AUG 2019.jpg"
import sep2019 from "../../images/covers/2019/SEP 2019.jpg"
import oct2019 from "../../images/covers/2019/OCT 2019.jpg"
import nov2019 from "../../images/covers/2019/NOV 2019.jpg"
import dec2019 from "../../images/covers/2019/DEC 2019.jpg"

import jan2020 from "../../images/covers/2020/JAN 2020.jpg"
import feb2020 from "../../images/covers/2020/FEB 2020.jpg"

export const y2014 = [
  { src: sep2014, date: "September 2014" },
  { src: oct2014, date: "October 2014" },
  { src: nov2014, date: "November 2014" },
  { src: dec2014, date: "December 2014" },
].reverse()

export const y2015 = [
  { src: jan2015, date: "January 2015" },
  { src: feb2015, date: "Febuary 2015" },
  { src: mar2015, date: "March 2015" },
  { src: apr2015, date: "April 2015" },
  { src: june2015, date: "June 2015" },
  { src: july2015, date: "July 2015" },
  { src: aug2015, date: "August 2015" },
  { src: sep2015, date: "September 2015" },
  { src: oct2015, date: "October 2015" },
  { src: nov2015, date: "November 2015" },
  { src: dec2015, date: "December 2015" },
].reverse()

export const y2016 = [
  { src: jan2016, date: "January 2016" },
  { src: feb2016, date: "February 2016" },
  { src: mar2016, date: "March 2016" },
  { src: apr2016, date: "April 2016" },
  { src: may2016, date: "May 2016" },
  { src: july2016, date: "July 2016" },
  { src: aug2016, date: "August 2016" },
  { src: sep2016, date: "September 2016" },
  { src: oct2016, date: "October 2016" },
  { src: nov2016, date: "November 2016" },
  { src: dec2016, date: "December 2016" },
].reverse()

export const y2017 = [
  { src: jan2017, date: "January 2017" },
  { src: feb2017, date: "February 2017" },
  { src: mar2017, date: "March 2017" },
  { src: apr2017, date: "April 2017" },
  { src: may2017, date: "May 2017" },
  { src: july2017, date: "July 2017" },
  { src: aug2017, date: "August 2017" },
  { src: sep2017, date: "September 2017" },
  { src: oct2017, date: "October 2017" },
  { src: nov2017, date: "November 2017" },
  { src: dec2017, date: "December 2017" },
].reverse()

export const y2018 = [
  { src: jan2018, date: "January 2018" },
  { src: feb2018, date: "February 2018" },
  { src: mar2018, date: "March 2018" },
  { src: apr2018, date: "April 2018" },
  { src: jul2018, date: "July 2018" },
  { src: aug2018, date: "August 2018" },
  { src: sep2018, date: "September 2018" },
  { src: oct2018, date: "October 2018" },
  { src: nov2018, date: "November 2018" },
  { src: dec2018, date: "December 2018" },
].reverse()

export const y2019 = [
  { src: jan2019, date: "January 2019" },
  { src: feb2019, date: "February 2019" },
  { src: mar2019, date: "March 2019" },
  { src: apr2019, date: "April 2019" },
  { src: jul2019, date: "July 2019" },
  { src: aug2019, date: "August 2019" },
  { src: sep2019, date: "September 2019" },
  { src: oct2019, date: "October 2019" },
  { src: nov2019, date: "November 2019" },
  { src: dec2019, date: "December 2019" },
].reverse()

export const y2020 = [
  { src: jan2020, date: "January 2020" },
  { src: feb2020, date: "February 2020" },
].reverse()
