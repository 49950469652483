import React, { useState } from "react"
import "./style.scss"
import Layout from "../layout"
import Navbar from "../navbar"
import { y2014, y2015, y2016, y2017, y2018, y2019, y2020 } from "./data"
import LazyLoad from "react-lazyload"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import SEO from "../seo"

const allIssues = [
  ...y2020,
  ...y2019,
  ...y2018,
  ...y2017,
  ...y2016,
  ...y2015,
  ...y2014,
]

export default function Issue() {
  function getIssuesJSX(issues) {
    const jsx = issues.map((issue, i) => (
      <div className="col col-6 col-md-4 col-lg-2">
        <div className="issues__card">
          {/* <LazyLoad>
            <img className="issues__card__img" alt="issue" src={issue} />
          </LazyLoad> */}
          <LazyLoadImage
            // alt={image.alt}
            src={issue.src} // use normal <img> attributes as props
            effect="blur"
            className="issues__card__img"
          />
          <div className="issues__card__date">{issue.date}</div>
        </div>
      </div>
    ))

    return jsx
  }

  const tabs = [
    { title: "all", issues: allIssues },
    { title: "2020", issues: y2020 },
    { title: "2019", issues: y2019 },
    { title: "2018", issues: y2018 },
    { title: "2017", issues: y2017 },
    { title: "2016", issues: y2016 },
    { title: "2015", issues: y2015 },
    { title: "2014", issues: y2014 },
  ]

  const [currentTab, setCurrentTab] = useState(tabs[0])

  const tabsJSX = tabs.map((tab, i) => (
    <div
      className={`issues__tabs__tab ${
        currentTab.title === tab.title && " issues__tabs__tab--selected"
      }`}
      onClick={() => setCurrentTab(tab)}
    >
      {tab.title}
    </div>
  ))

  return (
    <Layout>
      <SEO
        title="School LIVE | The Magazine Rack"
        description="Explore all issues of School LIVE magazine and get a sneak peak of the quality content on a variety of categories including international university admissions, Indian higher education, and innovative pedagogies."
      />
      <Navbar />
      <div className="issues">
        <hr className="hr" />
        <h1 className="heading-primary">Archives</h1>

        <div className="issues__tabs">{tabsJSX}</div>

        <div className="row issues__row">{getIssuesJSX(currentTab.issues)}</div>
      </div>
    </Layout>
  )
}
